.root {
  background: hsl(224, 19%, 12%);
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 18;
  color: white;
  min-height: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.paragraph {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.icon {
  margin: 0.5;
}

#siteseal img {
  height: 30px;
  margin: 5px;
}
